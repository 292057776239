import React from 'react'
import { Heading, Box, Text } from 'theme-ui'

/**
 * Shadow me to add your own content
 */

const styles = {
  wrapper: {
    ml: 3
  },
  heading: {
    color: `alpha`
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`
  }
}

export default () => (
    <Box sx={styles.wrapper}>
      <center><h2><a href="https://twitter.com/shakeshoutout">shake shoutout</a></h2>The top three domains won over the last 24 hours on the Handshake network.<br /><br />🏆 https://158/<br />5,001.00 HNS<br />0.01 ETH<br />$42.01<br /><br />🥈 https://bongs/<br />800.00 HNS<br />0.00 ETH<br />$6.72<br /><br />🥉 https://hailuoai/<br />26.00 HNS<br />0.00 ETH<br />$0.22<br /><br /><br /><br /><br /><br /><h2><a href="https://twitter.com/nbshoutout">nb shoutout</a></h2>The top three domains bought over the last 24 hours in the Namebase marketplace.<br /><br />🏆 https://optimism/<br />111,111.00 HNS<br />0.30 ETH<br />$934.69<br /><br />🥈 https://toto/<br />4,888.00 HNS<br />0.01 ETH<br />$41.12<br /><br />🥉 https://xn--8y9a/ 👉 https://ꭥ/<br />1,999.00 HNS<br />0.01 ETH<br />$16.82<br /><br /><br /><br /><br /><br /><h2><a href="https://twitter.com/shake_supply">shake supply</a></h2>The circulating HNS supply against the maximum supply.<br /><br />[ ▉▉▉▉▉▏▁▁▁▁▁▁▁▁▁▁▁ ] 31.35%<br /><br /><br /><br /><br /><br /><h2><a href="https://twitter.com/shakesplit">shake split</a></h2>The progress made toward the next Handshake halving.<br /><br />[ ▉▉▉▉▉▉▉▉▏▁▁▁▁▁▁▁▁ ] 48.0%<br /><br /><br /><br /><br /><br /><h2><a href="https://twitter.com/shakefoss">shake foss</a></h2>The claimed HNS against the total airdrop to FOSS developers. And the new and total claims since the last tweet.<br /><br />[ ▍▁▁▁▁▁▁▁▁▁▁▁▁▁▁▁▁ ] 2.91% (New: 0 Total: 6541)<br /><br /><br /><br /><br /><br /></center>
		</Box>
	)